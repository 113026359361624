/* global document location */
module.exports = (host) => {

    const labels = {
        www: {
            barClassName: '',
            label: '',
            subjectPrefix: 'PRO - '
        },
        develop: {
            barClassName: 'develop',
            label: 'DEV',
            subjectPrefix: 'DEV - '
        },
        load: {
            barClassName: 'develop',
            label: 'LOAD',
            subjectPrefix: 'LOAD - '
        },
        practice: {
            barClassName: 'practice',
            label: 'PRA',
            subjectPrefix: 'PRA - '
        },
        localhost: {
            barClassName: 'develop',
            label: 'LOC',
            subjectPrefix: 'LOC - '
        },
        'stage-1': {
            barClassName: 'develop',
            label: 'STA1',
            subjectPrefix: 'STA1 - '
        },
        'stage-2': {
            barClassName: 'develop',
            label: 'STA2',
            subjectPrefix: 'STA2 - '
        },
        'stage-3': {
            barClassName: 'develop',
            label: 'STA3',
            subjectPrefix: 'STA3 - '
        },
        'stage-4': {
            barClassName: 'develop',
            label: 'STA4',
            subjectPrefix: 'STA4 - '
        }
    };
    let label = '';
    let barClassName = '';
    let subjectPrefix = '';
    if (host && host.startsWith('http')) {
        host = host.substring(host.indexOf('://') + 3);
    }

    const key = Object.keys(labels).find(key => {
        return host && host.startsWith(key);
    });

    if ( key ) {
        barClassName = labels[key].barClassName;
        label = labels[key].label;
        subjectPrefix = labels[key].subjectPrefix;
    }

    return { barClassName, label, subjectPrefix };
};
