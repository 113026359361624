/* global document location */
const demoBannerEngine = require('./demo-banner-engine');

const host = location && location.host;
const { barClassName, label } = demoBannerEngine(host)
if ( label) {//so it runs client side

    const body = document.getElementsByTagName('body')[0];
    const elem = document.createElement('div');
    elem.innerHTML = label;
    elem.classList.add('devBanner');
    body.appendChild(elem);
    document.getElementById('nav-bar').classList.add(barClassName);
}
